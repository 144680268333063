.projectCards {
	display: flex;
	justify-content: flex-start;
	gap: 12px;
	margin: 12px 0 24px 0;
	padding: 0 12px;
	transition: transform .3s;

	.card {
			width: 44px;
			height: 44px;
			background: rgba(255, 255, 255, 0.05);
			border-radius: 13px;
			flex-shrink: 0;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			cursor: pointer;
			transition: background 0.3s, box-shadow 0.3s, opacity 0.3s, transform 0.3s;
			opacity: 0.4;
			
			// active border icon
			&.active {
					box-shadow: inset 0 0 0 2px #ffffff0d;
					opacity: 1;
			}

			.plusIcon {
					font-size: 24px;
					color: rgba(255, 255, 255, 0.5);
					text-align: center;
					justify-content: center;
			}
	}

	.cardIcon {
			position: absolute;
			width: 18px;
			height: 18px;
			z-index: 2;
	}

	.cardIconBlur {
			position: absolute;
			width: 24px;
			height: 24px;
			z-index: 1;
			opacity: 0;
			transition: filter 0.3s, opacity 0.3s;

			&.activeBlur {
					filter: blur(15px);
					opacity: 1;
			}
	}
}