

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #0D0D0E;
  // background-color: #0d0d10;
  background-color: #0D0D0E;
}

body, input, button {
  font-family: -apple-system, BlinkMacSystemFont, Inter, sans-serif;
}

button {
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}