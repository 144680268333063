.mainLayout {
    display: flex;
    height: 100vh;
    box-sizing: border-box;

    > main {
        width: 100%;
        background-color: rgba($color: #FFF, $alpha: 0.02);
        border-radius: 16px;
        padding: 24px;
        margin: 12px 12px 12px 0;

    }
}