.button {
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        transition: transform .2s;
    }

    &:active > * {
        transform: scale(0.8);
    }
}
