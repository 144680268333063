.sidebar {
    width: 300px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;


    transition: width .3s;
    user-select: none;  // safari
    padding: 16px;
    justify-content: space-between;
  
    &.collapsed {
      width: 68px;
        
        .top {

            .projectCards {    
                .card {
                    &:not(.active) {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }

            li {
                &.active {
                    background: transparent;
                }
            }
          
            .text {
                opacity: 0;
                visibility: hidden;
            }
        }

        .bottom {
            .user {
                background-color: transparent;

                .left {
                    opacity: 0;
                    visibility: hidden;
                }
                .logout {
                    right: 18px;
                }
            }
        }
      
    }

    .top, .bottom {
        overflow: hidden;
    }
    
    .top {
        .brand {
            display: flex;
            height: 74px;
            align-items: center;
            font-weight: 700;
            font-size: 24px;
            margin: 0 22px;
            color: #FFFFFF;
            cursor: pointer;
            white-space: nowrap;
            gap: 16px;

            > svg {
                flex-shrink: 0;
            }
        
            .brandText {
                user-select: none;
                transition: opacity 0.3s ease, visibility 0.3s ease;

                &.hidden {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .projectCards {
            display: flex;
            justify-content: flex-start;
            gap: 12px;
            margin: 12px 0 24px 0;
            padding: 0 12px;
            transition: transform .3s;
        
            .card {
                width: 44px;
                height: 44px;
                background: rgba(255, 255, 255, 0.05);
                border-radius: 13px;
                flex-shrink: 0;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                cursor: pointer;
                transition: background 0.3s, box-shadow 0.3s, opacity 0.3s, transform 0.3s;
                opacity: 0.4;
                
                // active border icon
                &.active {
                    box-shadow: inset 0 0 0 2px #ffffff0d;
                    opacity: 1;
                }

                .plusIcon {
                    font-size: 24px;
                    color: rgba(255, 255, 255, 0.5);
                    text-align: center;
                    justify-content: center;
                }
            }
        
            .cardIcon {
                position: absolute;
                width: 18px;
                height: 18px;
                z-index: 2;
            }
        
            .cardIconBlur {
                position: absolute;
                width: 24px;
                height: 24px;
                z-index: 1;
                opacity: 0;
                transition: filter 0.3s, opacity 0.3s;
        
                &.activeBlur {
                    filter: blur(15px);
                    opacity: 1;
                }
            }
        }
    
        ul {
            list-style: none;
            gap: 4px;
            display: flex;
            flex-direction: column;
        
            li {
                border-radius: 14px;
                transition: background 0.3s ease, opacity 0.3s ease, transform 0.1s ease;
                opacity: 0.25;
        
                &:active {
                    transform: scale(0.97);
                }
        
                &.active {
                    background: rgba(255, 255, 255, 0.05);
                    opacity: 1;
                }
        
                .navLink {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 16px;
                    text-decoration: none;

                    height: 54px;
                    padding: 0 20px;
                    gap: 16px;

                .text {
                    white-space: nowrap;
                    transition: opacity 0.3s ease, visibility 0.3s ease;
                }
        
                > svg {
                    flex-shrink: 0;
                }
                }
            }
        }
    }

    .bottom {
        .user {
            position: relative;
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 16px;
            transition: background-color 0.3s ease;

            
            width: 100%;
            padding: 16px 18px;
            box-sizing: border-box;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .logout {
                position: absolute;
                right: 16px;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 12px;

                transition: opacity 0.2s ease, visibility 0.2s ease;
         
                .data {
                    display: flex;
                    flex-direction: column;

                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    
                    gap: 4px;

                    .email, .plan {
                        line-height: 100%;
                    }
            
                    .plan {
                        color: rgba(255, 255, 255, 0.2);
                    }
                }
            } 
        }
    }
  }